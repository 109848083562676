@import "../font/inter";
@import "../icon/default";

:root {
  --font-family: 'Inter', sans-serif;
  --font-family-brand: 'Times', serif;
  --font-family-code: 'Menlo', monospace;

  --background: #ffffff;
  --color: #355265;
  --color2: #274457;
  --color3: #476d86;

  --color-anchor: #328ac1;
  --color-hover: #4b9dd0;

  --background-fg: #f7f7f7;
  --background-fg2: #ebebeb;
  --border-color: #dddddd;

  --box-shadow: 0 0 1px rgba(0, 0, 0, .7);
  --box-shadow2: 0 0 3px rgba(0, 0, 0, .2);

  --blur: 10px;

  --home-cover-background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,250,1) 25%, rgba(214,219,220,1) 50%, rgba(255,255,250,1) 75%, rgba(255,255,255,1) 100%);

  --icon-filter: invert(41%) sepia(19%) saturate(840%) hue-rotate(161deg) brightness(92%) contrast(92%);

  /* base16 tomorrow */
  --chroma-base00: #f9f9f9;
  --chroma-base01: #e0e0e0;
  --chroma-base02: rgba(159, 218, 159, .2);
  --chroma-base03: #8e908c;
  --chroma-base04: #969896;
  --chroma-base05: #4d4d4c;
  --chroma-base06: #282a2e;
  --chroma-base07: #1d1f21;
  --chroma-base08: #c82829;
  --chroma-base09: #f5871f;
  --chroma-base0A: #eab700;
  --chroma-base0B: #718c00;
  --chroma-base0C: #3e999f;
  --chroma-base0D: #4271ae;
  --chroma-base0E: #8959a8;
  --chroma-base0F: #a3685a;
}

:root[data-color="dark"] {
  --background: #121212;
  --color: #efefef;
  --color2: #ffffff;
  --color3: #b3b3b3;

  --background-fg: #333333;
  --background-fg2: #1f1f1f;
  --border-color: rgba(255, 255, 255, .4);

  --box-shadow: 0 0 1px rgba(255, 255, 255, 1);
  --box-shadow2: 0 0 3px rgba(255, 255, 255, .6);

  --home-cover-background: radial-gradient(circle, rgba(23,23,25,1) 0%, rgba(18,18,0,1) 25%, rgba(32,32,32,1) 50%, rgba(18,18,0,1) 75%, rgba(23,23,25,1) 100%);

  --icon-filter: invert(83%) sepia(0%) saturate(1582%) hue-rotate(126deg) brightness(86%) contrast(80%);

  /* base16 tomorrow night */
  --chroma-base00: #080808;
  --chroma-base01: #393939;
  --chroma-base02: rgba(159, 218, 159, .1);
  --chroma-base03: #999999;
  --chroma-base04: #b4b7b4;
  --chroma-base05: #cccccc;
  --chroma-base06: #e0e0e0;
  --chroma-base07: #ffffff;
  --chroma-base08: #f2777a;
  --chroma-base09: #f99157;
  --chroma-base0A: #ffcc66;
  --chroma-base0B: #99cc99;
  --chroma-base0C: #66cccc;
  --chroma-base0D: #6699cc;
  --chroma-base0E: #cc99cc;
  --chroma-base0F: #a3685a;
}

:root[data-color="night"] {
  --background: #333333;
  --color: #cccccc;
  --color2: #dedede;
  --color3: #9d9d9d;

  --background-fg: #444444;
  --background-fg2: #303030;
  --border-color: rgba(255, 255, 255, 0.2);

  --box-shadow: 0 0 1px rgba(225, 255, 255, 1);
  --box-shadow2: 0 0 3px rgba(255, 255, 255, .6);

  --home-cover-background: radial-gradient(circle, rgba(52,52,52,1) 0%, rgba(42,42,42,1) 25%, rgba(57,57,57,1) 50%, rgba(42,42,42,1) 75%, rgba(52,52,52,1) 100%);

  --icon-filter: invert(60%) sepia(25%) saturate(20%) hue-rotate(343deg) brightness(98%) contrast(94%);

  /* base16 twilight */
  --chroma-base00: #1e1e1e;
  --chroma-base01: #323537;
  --chroma-base02: rgba(159, 218, 159, .1);
  --chroma-base03: #5f5a60;
  --chroma-base04: #838184;
  --chroma-base05: #a7a7a7;
  --chroma-base06: #c3c3c3;
  --chroma-base07: #ffffff;
  --chroma-base08: #cf6a4c;
  --chroma-base09: #cda869;
  --chroma-base0A: #f9ee98;
  --chroma-base0B: #8f9d6a;
  --chroma-base0C: #afc4db;
  --chroma-base0D: #7587a6;
  --chroma-base0E: #9b859d;
  --chroma-base0F: #9b703f;
}

.icon:not(.icon-colored) {
  filter: var(--icon-filter);
}